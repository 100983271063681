.errorfallbackui {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 22px;
  margin-top: 20vh;
  text-align: center;
}
.title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 27px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.title svg {
  transform: scale(2);
  margin-bottom: 15px;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
}

.retrysteps {
  font-size: 16px;
}
